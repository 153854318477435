import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import {filterTranslation} from "../../../helpers";

// migrated bit components
import loginModalTr from "../../../components/_bit/tm-community-login-widget/translations";
import emailLoginModalTr from "../../../components/_bit/tm-email-login-otp-widget/translations";
import verifyEmailWidgetTr from "../../../components/_bit/tm-verify-otp-widget/translations";
import forgotPasswordWidgetTr from "../../../components/_bit/tm-forgot-password-otp-widget/translations";
import confirmWidgetTr from "@tickled-media/web-components.tm-confirm-widget/translations.js";

i18n
    .use(LanguageDetector)
    .use(Backend)
    .init({
        whitelist: ['en', 'th', 'id', 'my', 'vn', 'ph'],
        fallbackLng: 'en',
        debug: false,
        ns: ['common'],
        defaultNS: 'common',
        backend: {
            loadPath: '{{lng}}/{{ns}}',
            parse: (data) => data,
            ajax: loadLocales
        }
    });


function loadLocales(url, options, callback) {
    try {
        const locale = url.split('/')[0];
        const loginTr = filterTranslation(locale, loginModalTr);
        const emailLoginTr = filterTranslation(locale, emailLoginModalTr);
        const verifyEmailTr = filterTranslation(locale, verifyEmailWidgetTr);
        const confirmTr = filterTranslation(locale, confirmWidgetTr);
        const forgotPasswordTr = filterTranslation(locale, forgotPasswordWidgetTr);
        const localeData = require('../locales/' + url + '.json');
        const data = {
            ...localeData,
            ...loginTr,
            ...emailLoginTr,
            ...verifyEmailTr,
            ...confirmTr,
            ...forgotPasswordTr,
        };
        callback(data, {status: '200'});
    } catch (e) {
        console.log(e);
        callback(null, {status: '404'});
    }
}

export default i18n;