/**
* [translations description]
* @type {Object}
*/

export default {
    en: {
        "verifyEmailHeadingText" : "Enter the One Time Password to verify the email address",
        "verifyEmailDescriptionText" : "One-time password has been sent on",
        "verifyBtnText" : "Verify",
        "signupFormHeading" : "What do we call you at theAsianparent?",
        "signupFormDescription" : "Password must include minimum 8 characters and no spaces allowed. Use mix of symbols, letters and numbers to make it more secure.",
        "nameInputPlaceholder" : "Enter your name",
        "createPwdPlaceholder" : "Create password",
        "enterPwdPlaceholder" : "Enter password",
        "confirmPwdPlaceholder" : "Confirm password",
        "inputRequired" : "Input Required",
        "submitBtnText" : "Submit",
        "didNotReceiveOptText" : "Didn’t receive the OTP? ",
        "resend" : "Resend",
        "otpTimeoutText" : "Time Out! Click on resend to receive again.",
        "checkMailText" : "If OTP is not received, please check spam in your email inbox.",
        "smsNotReceived" : "OTP still not received? Apologies for the delay. Please wait for a minute before clicking on resend button again.",
        "openEmailAppText" : "Open Email App",
        "confirmMessageVerify" : "If you wish to continue going back, this registration will cancel and you need to sign-up again.",
        "pwdNotValidText" : "Password is not valid",
        "pwdDoesNOtMatchText" : "Password not matching.",
        "verifyMobileHeadingText" : "Enter the One Time Password to verify the mobile number",
        "somethingWentWrong" : "Something went wrong",
        "optCheckSpamText" : "OTP not received? Please check spam in your email inbox.",
        "notification" : "Notifications",
    },
    id: {
        "verifyEmailHeadingText" : "Masukkan kata sandi satu kali untuk memverifikasi alamat email",
        "verifyEmailDescriptionText" : "Kata sandi sekali pakai telah dikirim melalui",
        "verifyBtnText" : "verifikasi",
        "signupFormHeading" : "Apa yang kami sebut Anda di Theasianparent?",
        "signupFormDescription" : "Kata kunci harus mengandung minimal 8 karakter dan tidak ada spasi. Gunakan kombinasi simbol, huruf dan angka agar kata kunci lebih aman.",
        "nameInputPlaceholder" : "Masukkan nama Anda",
        "createPwdPlaceholder" : "Buat Kata Sandi",
        "confirmPwdPlaceholder" : "konfirmasi sandi",
        "enterPwdPlaceholder" : "Masukkan kata kunci",
        "inputRequired" : "Input diperlukan",
        "submitBtnText" : "Kirim",
        "didNotReceiveOptText" : "Tidak menerima OTP? ",
        "resend" : "Kirim ulang",
        "otpTimeoutText" : "Waktu habis! Klik KIRIM ULANG UNTUK MENERIMA LAGI.",
        "checkMailText" : "Jika OTP tidak diterima, silakan periksa spam di kotak masuk email Anda.",
        "smsNotReceived" : "Masih belum menerima OTP? Mohon maaf atas keterlambatannya. Mohon tunggu beberapa menit sebelum menekan lagi tombol kirim lagi.",
        "openEmailAppText" : "Buka Email Aplikasi",
        "confirmMessageVerify" : "Jika Anda ingin kembali ke laman sebelumnya, registrasi ini akan batal dan Anda harus mendaftar lagi.",
        "pwdNotValidText" : "Kata sandi tidak valid.",
        "pwdDoesNOtMatchText" : "Kata sandi tidak cocok.",
        "verifyMobileHeadingText" : "Masukkan Kata Kunci Satu Kali untuk memverifikasi nomor telepon",
        "somethingWentWrong" : "Sesuatu tidak berjalan dengan semestinya",
        "optCheckSpamText" : "OTP tidak diterima? Silakan periksa spam di kotak masuk email Anda.",
        "notification" : "Pemberitahuan",
    },
    th: {
        "verifyEmailHeadingText" : "ป้อนรหัสผ่านครั้งเดียวเพื่อยืนยันที่อยู่อีเมล์",
        "verifyEmailDescriptionText" : "รหัสผ่านแบบใช้ครั้งเดียวถูกส่งไปที่",
        "verifyBtnText" : "ตรวจสอบ",
        "signupFormHeading" : "คุณต้องการให้เราเรียกคุณว่าอะไรที่ theAsianparent?",
        "signupFormDescription" : "รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัวและห้ามเว้นวรรค ใช้สัญลักษณ์ ตัวอักษร และตัวเลขผสมกันเพื่อให้รหัสผ่านมีความปลอดภัยยิ่งขึ้น",
        "nameInputPlaceholder" : "ชื่อ-นามสกุล ภาษาไทย",
        "createPwdPlaceholder" : "สร้างรหัสผ่าน",
        "confirmPwdPlaceholder" : "ยืนยันรหัสผ่าน",
        "enterPwdPlaceholder" : "ใส่รหัสผ่าน",
        "inputRequired" : "ใส่ข้อมูลที่จำเป็น",
        "submitBtnText" : "ส่ง",
        "didNotReceiveOptText" : "หากไม่ได้รับ OTP? ",
        "resend" : "ส่งอีกครั้ง",
        "otpTimeoutText" : "หมดเวลา! คลิกส่งใหม่เพื่อรับอีกครั้ง",
        "checkMailText" : "หากไม่ได้รับ OTP โปรดตรวจสอบสแปมในกล่องจดหมายอีเมลของคุณ",
        "smsNotReceived" : "ยังไม่ได้รับ OTP? ขออภัยในความล่าช้า กรุณารอสักครู่ก่อนที่จะคลิกที่ปุ่มส่งใหม่อีกครั้ง",
        "openEmailAppText" : "เปิดแอปอีเมล",
        "confirmMessageVerify" : "หากคุณต้องการย้อนกลับ การลงทะเบียนครั้งนี้จะถูกยกเลิกและคุณต้องสมัครใหม่อีกครั้ง",
        "pwdNotValidText" : "รหัสผ่านไม่ถูกต้อง",
        "pwdDoesNOtMatchText" : "รหัสผ่านไม่ตรงกัน",
        "verifyMobileHeadingText" : "ป้อนรหัสผ่านครั้งเดียวเพื่อยืนยันหมายเลขโทรศัพท์มือถือ",
        "somethingWentWrong" : "เกิดข้อผิดพลาด",
        "optCheckSpamText" : "ไม่ได้รับรหัส OTP? โปรดตรวจสอบสแปมในกล่องจดหมายอีเมลของคุณ",
        "notification" : "การแจ้งเตือน ",
    },
    fil: {
        "verifyEmailHeadingText" : "I-enter ang One Time Password para i-verify ang email address",
        "verifyEmailDescriptionText" : "One time password ay napadala na sa",
        "verifyBtnText" : "Verify",
        "signupFormHeading" : "Anong ipapangalan namin sayo sa theAsianparent?",
        "signupFormDescription" : "Ang password ay dapat mayroong minimum na 8 karakter at walang spaces. Gumamit ng symbols, letra at numero upang mas secured ito.",
        "nameInputPlaceholder" : "Ilagay ang iyong pangalan",
        "createPwdPlaceholder" : "Create password",
        "confirmPwdPlaceholder" : "Sahkan Kata Laluan",
        "enterPwdPlaceholder" : "Enter password",
        "inputRequired" : "Input Required",
        "submitBtnText" : "Submit",
        "didNotReceiveOptText" : "Hindi natanggap ang OTP? ",
        "resend" : "Resend",
        "otpTimeoutText" : "Time Out! Pindutin ang resend para matanggap ulit.",
        "checkMailText" : "Kapag ang OTP ay hindi natanggap, hanapin sa Spam ng iyong email inbox.",
        "smsNotReceived" : "Hindi pa din na-recieve ang OTP? Pasensya sa delay. Paki-hintay ng isang minuto bago mag click sa resend button muli.",
        "openEmailAppText" : "Open Email App",
        "confirmMessageVerify" : "Kapag gusto mong bumalik, ang registration na ito ay maka-kansel at kailangan mong mu mag sign-up.",
        "pwdNotValidText" : "Password ay hindi valid.",
        "pwdDoesNOtMatchText" : "Password not matching.",
        "verifyMobileHeadingText" : "Ilagay ang One Time Password para i-verify ang mobile number",
        "somethingWentWrong" : "Something went wrong",
        "optCheckSpamText" : "Hindi natanggap ang OTP? Paki-check ang spam sa'yong email inbox.",
        "notification" : "Notifications",
    },
    ms: {
        "verifyEmailHeadingText" : "Masukkan OTP untuk mengesahkan emel",
        "verifyEmailDescriptionText" : "OTP telah dihantar ke",
        "verifyBtnText" : "Verify",
        "signupFormHeading" : "Anda ingin dipanggil apa di theAsianparent?",
        "signupFormDescription" : "Kata laluan harus mengandungi 8 aksara dan jarak tidak dibenarkan. Guna kombinasi simbol, huruf dan nombor supaya lebih selamat.",
        "nameInputPlaceholder" : "Masukkan nama",
        "createPwdPlaceholder" : "Cipta Kata Laluan",
        "confirmPwdPlaceholder" : "Sahkan Kata Laluan",
        "enterPwdPlaceholder" : "Masukkan kata laluan",
        "inputRequired" : "Butiran Diperlukan",
        "submitBtnText" : "Hantar",
        "didNotReceiveOptText" : "Tidak menerima OTP? ",
        "resend" : "Hantar semula",
        "otpTimeoutText" : "Masa Tamat! Klik pada hantar semula untuk OTP yang baru. ",
        "checkMailText" : "Jika tidak menerima OTP, sila semak 'spam' dalam peti masuk emel anda.",
        "smsNotReceived" : "OTP masih tidak diterima? Mohon maaf atas kelewatan. Sila tunggu sebentar sebelum mengklik pada butang hantar semula sekali lagi.",
        "openEmailAppText" : "Buka Aplikasi Emel",
        "confirmMessageVerify" : "Jika anda ingin kembali ke laman sebelumnya, pendaftaran anda akan terbatal dan anda perlu mendaftar semula.",
        "pwdNotValidText" : "Kata laluan tidak sah.",
        "pwdDoesNOtMatchText" : "Kata laluan tidak sama.",
        "verifyMobileHeadingText" : "Masukkan OTP untuk mengesahkan nombor telefon",
        "somethingWentWrong" : "Ada sesuatu yang tidak kena!",
        "optCheckSpamText" : "Tidak menerima OTP? Sila semak 'spam' dalam peti masuk emel anda.",
        "notification" : "Notifications",
    },
    vi: {
        "verifyEmailHeadingText" : "Nhập Mật khẩu một lần để xác minh địa chỉ email.",
        "verifyEmailDescriptionText" : "Mật khẩu một lần đã được gửi vào email của bạn",
        "verifyBtnText" : "Xác minh",
        "signupFormHeading" : "Tại Bé Yêu, chúng tôi gọi bạn là gì?",
        "signupFormDescription" : "Mật khẩu phải bao gồm tối thiểu 8 ký tự và không có dấu cách. Sử dụng kết hợp các ký hiệu, chữ cái và số để tăng tính an toàn.",
        "nameInputPlaceholder" : "Nhập tên của bạn",
        "createPwdPlaceholder" : "Tạo mật khẩu",
        "confirmPwdPlaceholder" : "Sahkan Kata Laluan",
        "enterPwdPlaceholder" : "Nhập mật khẩu",
        "inputRequired" : "Thông tin bắt buộc",
        "submitBtnText" : "Gửi đi",
        "didNotReceiveOptText" : "Bạn chưa nhận được OTP? ",
        "resend" : "Hãy bấm gửi lại.",
        "otpTimeoutText" : "Hết giờ! Nhấn vào Gửi lại để nhận lại.",
        "checkMailText" : "Nếu không nhận được mã OTP, vui lòng kiểm tra thư rác trong email của bạn.",
        "smsNotReceived" : "Vẫn không nhận được OTP? Xin lỗi vì sự chậm trễ này. Vui lòng đợi một phút trước khi bấm lại vào nút gửi lại.",
        "openEmailAppText" : "Mở ứng dụng email",
        "confirmMessageVerify" : "Nếu bạn muốn quay lại, đăng ký này sẽ bị hủy và bạn sẽ phải đăng ký lại.",
        "pwdNotValidText" : "Mật khẩu không hợp lệ.",
        "pwdDoesNOtMatchText" : "Mật khẩu không khớp.",
        "verifyMobileHeadingText" : "Nhập mật khẩu một lần để xác minh số điện thoại di động.",
        "somethingWentWrong" : "Đã xảy ra sự cố",
        "optCheckSpamText" : "Không nhận được OTP? Vui lòng kiểm tra thư rác trong email của bạn..",
        "notification" : "Thông báo",
    }
}

    