/**
* [translations description]
* @type {Object}
*/

export default {
    en: {
        "tncAcceptance": "I accept theAsianparent",
        "and": "and the",
        "tnc": " Terms & Conditions ",
        "guidelines": " Community Guidelines.",
        "loginHeading" : "Get expert-led tips and connect with other parents",
        "emailBtnText" : "Continue with Email",
        "emailMobileBtnText" : "Continue with Email or Mobile",
        "googleBtnText" : "Continue with Google",
        "facebookBtnText" : "Continue with Facebook",
        "alertAcceptanceMessage": "Please accept the Terms & Conditions to continue",
        "notification" : "Notifications",
    },
    id: {
        "tncAcceptance": "Saya menerima Theasianparent",
        "and": "dan",
        "tnc": " Syarat dan ketentuan ",
        "guidelines": " Pedoman Komunitas",
        "loginHeading" : "Dapatkan tips langsung dari ahli dan terhubung dengan orang tua lainnya.",
        "emailBtnText" : "Teruskan dengan Email",
        "emailMobileBtnText" : "Teruskan dengan Email atau ponsel",
        "googleBtnText" : "Teruskan dengan Google",
        "facebookBtnText" : "Teruskan dengan Facebook",
        "alertAcceptanceMessage": "Please accept the Terms & Conditions to continue",
        "notification" : "Pemberitahuan",
    },
    th: {
        "tncAcceptance": "ฉันยอมรับ theAsianparent",
        "and": "และ",
        "tnc": " ข้อตกลงและเงื่อนไข ",
        "guidelines": "หลักเกณฑ์ของชุมชน",
        "loginHeading" : "รับคำแนะนำจากผู้เชี่ยวชาญและติดต่อกับผู้ปกครองคนอื่น ๆ",
        "emailBtnText" : "ต่อด้วย Email",
        "emailMobileBtnText" : "ต่อด้วย อีเมล์หรือโทรศัพท์",
        "googleBtnText" : "ต่อด้วย กูเกิ้ล",
        "facebookBtnText" : "ต่อด้วย เฟซบุ๊ค",
        "alertAcceptanceMessage": "Please accept the Terms & Conditions to continue",
        "notification" : "การแจ้งเตือน ",
    },
    fil: {
        "tncAcceptance": "Tinatanggap ko ang theAsianparent ",
        "tnc": "Terms and Conditions",
        "and": "at ang",
        "guidelines": " at sa Community Guidelines",
        "loginHeading" : "Kumuha ng payo mula sa eksperto at kumonekta sa ibang mga magulang. ",
        "emailBtnText" : "Magpatuloy sa Email",
        "emailMobileBtnText" : "Magpatuloy sa Email or Mobile",
        "googleBtnText" : "Magpatuloy sa Google",
        "facebookBtnText" : "Magpatuloy sa Facebook",
        "alertAcceptanceMessage": "Please accept the Terms & Conditions to continue",
        "notification" : "Notifications",
    },
    ms: {
        "tncAcceptance": "Saya terima",
        "and": "dan",
        "tnc": " Terma & Syarat theAsianparent ",
        "guidelines": " Garis Panduan Komuniti",
        "loginHeading" : "Dapatkan tips pakar dan berhubung dengan para ibu bapa.",
        "emailBtnText" : "Teruskan dengan Emel",
        "emailMobileBtnText" : "Teruskan dengan Emel atau Telefon Bimbit",
        "googleBtnText" : "Teruskan dengan Google",
        "facebookBtnText" : "Teruskan dengan Facebook",
        "alert_acceptance_message": "Please accept the Terms & Conditions to continue",
        "notification" : "Notifications",
    },
    vi: {
        "tncAcceptance": "Tôi chấp nhận",
        "tnc": " Điều khoản & Điều kiện ",
        "and": "và",
        "guidelines": " Hướng dẫn Cộng đồng",
        "loginHeading" : "Nhận các mẹo của chuyên gia và kết nối với các phụ huynh khác.",
        "emailBtnText" : "Tiếp tục với Email",
        "emailMobileBtnText" : "Tiếp tục với Email hoặc Số điện thoại",
        "googleBtnText" : "Tiếp tục với Google",
        "facebookBtnText" : "Tiếp tục với Facebook",
        "alertAcceptanceMessage": "Please accept the Terms & Conditions to continue",
        "notification" : "Thông báo",
    }
}


    