/**
* [translations description]
* @type {Object}
*/

export default {
    en: {
        "emailMessage": "Click on send to receive password reset link on your email address",
        "mobileMessage": "Click on send to receive password reset link message on your mobile number",
        "send": "Send Link",
        "ok": "Ok",
        "passwordResetLink1": "Password reset link sent to",
        "passwordResetLink2": "Please follow the steps as guided in the email.",
        "passwordResetLinkMobile": "Password Reset Link sent on your registered mobile number.",
        "emailInputPlaceholder" : "Enter Email address",
    },
    id: {
        "emailMessage": "Klik Kirim untuk menerima tautan reset kata sandi di alamat email Anda",
        "mobileMessage": "Klik kirim untuk menerima tautan reset kata sandi di nomor telepon Anda",
        "send": "Kirim tautan",
        "ok": "Oke",
        "passwordResetLink1": "Tautan Reset Kata Sandi telah dikirim di alamat",
        "passwordResetLink2": "silakan ikuti langkah-langkah di dalam email.",
        "passwordResetLinkMobile": "Tautan Reset Kata Sandi telah dikirim pada nomor ponsel Anda yang terdaftar.",
        "emailInputPlaceholder" : "Masukkan alamat email",
    },
    th: {
        "emailMessage": "คลิกส่งเพื่อรับลิงก์รีเซ็ตรหัสผ่านในที่อยู่อีเมลของคุณ",
        "mobileMessage": "คลิกส่งเพื่อรับลิงก์สำหรับรีเซ็ตรหัสผ่านที่หมายเลขโทรศัพท์มือถือของคุณ",
        "send": "ส่งลิงก์",
        "ok": "ตกลง",
        "passwordResetLink1": "ลิงก์รีเซ็ตรหัสผ่านถูกส่งไปยังที่อยู่",
        "passwordResetLink2": "โปรดทำตามขั้นตอนที่แนะนำในอีเมล",
        "passwordResetLinkMobile": "ลิงก์สำหรับรีเซ็ตรหัสผ่านถูกส่งไปยังหมายเลขโทรศัพท์มือถือของคุณที่ลงทะเบียนไว้",
        "emailInputPlaceholder" : "ป้อนที่อยู่อีเมล์",
    },
    fil: {
        "emailMessage": "Pindutin ang send  para makatanggap ng password reset link sa'yong email address",
        "mobileMessage": "I-click ang send para makatanggap ng password reset link message sa iyong mobile number",
        "send": "Send Link",
        "ok": "Ok",
        "passwordResetLink1": "Password reset link ay napadala na sa",
        "passwordResetLink2": "paki-sundan ang instructions na nasa email.",
        "passwordResetLinkMobile": "Password Reset Link ay na-send na sa'yong registered mobile number.",
        "emailInputPlaceholder" : "Enter Email address",
    },
    ms: {
        "emailMessage": "Klik hantar untuk menerima Pautan Menetap Semula Kata Laluan pada emel anda.",
        "mobileMessage": "Klik hantar untuk menerima Pautan Menetap Semula Kata Laluan pada nombor telefon anda.",
        "send": "Hantar Pautan",
        "ok": "Ok",
        "passwordResetLink1": "Pautan Menetap Semula Kata Laluan telah dihantar ke",
        "passwordResetLink2": "sila ikuti langkah yang telah ditetapkan di dalam emel.",
        "passwordResetLinkMobile": "Pautan Menetap Semula Kata Laluan telah dihantar ke nombor telefon anda yang didaftarkan.",
        "emailInputPlaceholder" : "Masukkan emel",
    },
    vi: {
        "emailMessage": "Bấm Gửi để nhận được liên kết đặt lại mật khẩu trong email của bạn.",
        "mobileMessage": "Bấm gửi để nhận tin nhắn có liên kết đặt lại mật khẩu trên điện thoại di động của bạn.",
        "send": "Gửi liên kết",
        "ok": "OK",
        "passwordResetLink1": "Liên kết đặt lại mật khẩu đã được gửi vào",
        "passwordResetLink2": "hãy làm theo các bước hướng dẫn trong email nhé.",
        "passwordResetLinkMobile": "Liên kết đặt lại mật khẩu đã được gửi về số điện thoại mà bạn đăng ký.",
        "emailInputPlaceholder" : "Nhập địa chỉ email",
    }
}



    