/**
* [translations description]
* @type {Object}
*/

export default {
    en: {
        "tapHeading" : "Welcome to theAsianparent",
        "emailInputPlaceholder" : "Enter Email address",
        "pwdInputPlaceholder" : "Enter Password",
        "forgotPwdText" : "Forgot Password?",
        "signInText" : "Sign-in",
        "emailNotRegisterText" : "The email address is not registered with theAsianparent.",
        "verifyOtpText" : "Please verify this email address with OTP to continue further.",
        "sendOtpBtnText" : "Send OTP",
        "invalidErrorMessageText" : "Oops, this is an invalid email address. Please provide the correct one.",
        "invalidEmailCharacterRule" : "Lowercase or Uppercase (a-z) English letters",
        "invalidEmailNumberRule" : "Numbers (0-9).",
        "invalidEmailSpecialCharacterRule" : "Characters like period (.), apostrophe ('), dash (-), hash (#), percent (%), ampersand (&), slash (/),underscore (_) are allowed",
        "invalidEmailSpecialSymbolRule" : "Symbol characters can’t be first or last character and it will not come one after the other",
        "invalidEmailDomainRule" : "@ can be used only one in between prefix & domain",
        "invalidEmailSectionHeaderDescriptionText" : "The email address must include only RFC-compliant characters, which include:",
        "emailAddressPrerequisitesText" : "Email Address Prerequisites",
        "confirmMessageEmail" : "Are you sure you want to cancel?",
        "invalidMobileNumberText" : "Oops, this is an invalid mobile number. Please provide the correct one.",
        "enterMobileNumber" : "Enter Mobile Number",
        "continue" : "Continue",
        "mobileNotRegistered" : "The mobile number is not registered with theAsianparent.",
        "verifyMobileNumber" : "Please verify this number with OTP to continue further.",
        "emailAddress" : "Email Address",
        "mobileNumber" : "Mobile Number",
        "somethingWentWrong" : "Something went wrong",
        "infoText" : "info",
        "notification" : "Notifications",
    },
    id: {
        "tapHeading" : "Selamat datang di theAsianparent",
        "emailInputPlaceholder" : "Masukkan alamat email",
        "pwdInputPlaceholder" : "Masukkan kata kunci",
        "forgotPwdText" : "Tidak ingat kata sandi?",
        "signInText" : "Masuk",
        "emailNotRegisterText" : "Alamat email belum terdaftar di theAsianparent.",
        "verifyOtpText" : "Untuk melanjutkan, mohon verifikasi alamat email ini dengan OTP.",
        "sendOtpBtnText" : "Kirim OTP",
        "invalidErrorMessageText" : "Oops, alamat email ini valid. Mohon berikan email yang benar.",
        "invalidEmailCharacterRule" : "Huruf kecil atau Kapital (a-z) dari bahasa Indonesia",
        "invalidEmailNumberRule" : "Numbers (0-9).",
        "invalidEmailSpecialCharacterRule" : "Karakter seperti titik (.), tanda petik ('), tanda pisah (-), tagar (#), persen (%), dan (&), garis miring (/) dan garis bawah (_) diperbolehkan.",
        "invalidEmailSpecialSymbolRule" : "Karakter simbol tidak bisa menjadi karakter awal atau terakhir dan tidak muncul berdampingan.",
        "invalidEmailDomainRule" : "@ hanya bisa digunakan antara awalan dan domain",
        "invalidEmailSectionHeaderDescriptionText" : "Alamat email harus memuat hanya karakter yang sesuai dengan RFC, yang termasuk: ",
        "emailAddressPrerequisitesText" : "Prasyarat alamat email",
        "confirmMessageEmail" : "Anda yakin ingin membatalkan proses ini?",
        "invalidMobileNumberText" : "Oops, nomor telepon ini tidak valid. Mohon sediakan nomor yang aktif.",
        "enterMobileNumber" : "Masukkan nomor telepon",
        "continue" : "Lanjut",
        "mobileNotRegistered" : "Nomor telepon ini tidak terdaftar di theAsianparent.",
        "verifyMobileNumber" : "Mohon verifikasi nomor ini dengan OTP untuk melanjutkan.",
        "emailAddress" : "Alamat email",
        "mobileNumber" : "Nomor handphone",
        "somethingWentWrong" : "Sesuatu tidak berjalan dengan semestinya",
        "infoText" : "info",
        "notification" : "Pemberitahuan",
    },
    th: {
        "tapHeading" : "ยินดีต้อนรับสู่ theAsianparent",
        "emailInputPlaceholder" : "ป้อนที่อยู่อีเมล์",
        "pwdInputPlaceholder" : "ใส่รหัสผ่าน",
        "forgotPwdText" : "ลืมรหัสผ่าน?",
        "signInText" : "เข้าสู่ระบบ",
        "emailNotRegisterText" : "ที่อยู่อีเมลนี้ไม่ได้ลงทะเบียนกับทาง theAsianparent",
        "verifyOtpText" : "โปรดยืนยันที่อยู่อีเมลนี้ด้วย OTP เพื่อดำเนินการต่อไป",
        "sendOtpBtnText" : "ส่งรหัส OTP",
        "invalidErrorMessageText" : "ที่อยู่อีเมลนี้ไม่ถูกต้อง โปรดระบุข้อมูลที่ถูกต้อง",
        "invalidEmailCharacterRule" : "ตัวอักษรภาษาอังกฤษตัวพิมพ์เล็กหรือตัวพิมพ์ใหญ่ (a-z)",
        "invalidEmailNumberRule" : "Numbers (0-9).",
        "invalidEmailSpecialCharacterRule" : "สามารถใช้อักษร เช่น จุด (.), อะพอสทรอฟี ('), เส้นประ (-), แฮช (#), เปอร์เซ็นต์ (%), เครื่องหมายและ (&), เครื่องหมายทับ (/), ขีดล่าง (_)",
        "invalidEmailSpecialSymbolRule" : "อักษรสัญลักษณ์ไม่สามารถเป็นอักษรตัวแรกหรือตัวสุดท้าย และจะไม่สามารถนำมาต่อกันได้",
        "invalidEmailDomainRule" : "@ ใช้ได้เพียงอันเดียวระหว่างคำนำหน้าและโดเมน",
        "invalidEmailSectionHeaderDescriptionText" : "อีเมล์ต้องประกอบด้วยตัวอักษรที่สอดคล้องกับ RFC ซึ่งรวมถึง:",
        "emailAddressPrerequisitesText" : "ข้อกำหนดเบื้องต้นของที่อยู่อีเมล",
        "confirmMessageEmail" : "คุณแน่ใจใช่ไหมที่จะยกเลิก?",
        "invalidMobileNumberText" : "หมายเลขโทรศัพท์นี้ไม่ถูกต้อง โปรดระบุข้อมูลที่ถูกต้อง",
        "enterMobileNumber" : "ป้อนหมายเลขโทรศัพท์มือถือ",
        "continue" : "ทำต่อไป",
        "mobileNotRegistered" : "หมายเลขโทรศัพท์มือถือไม่ได้ลงทะเบียนกับ theAsianparent",
        "verifyMobileNumber" : "กรุณายืนยันหมายเลขนี้ด้วย OTP เพื่อดำเนินการต่อไป",
        "emailAddress" : "ที่อยู่อีเมล์",
        "mobileNumber" : "หมายเลขโทรศัพท์",
        "somethingWentWrong" : "เกิดข้อผิดพลาด",
        "infoText" : "info",
        "notification" : "การแจ้งเตือน ",
    },
    fil: {
        "tapHeading" : "Welcome sa theAsianparent",
        "emailInputPlaceholder" : "Enter Email Address",
        "pwdInputPlaceholder" : "Enter Password",
        "forgotPwdText" : "Forgot Password?",
        "signInText" : "Sign-in",
        "emailNotRegisterText" : "Ang email address na ito ay hindi naka-register sa theAsianparent.",
        "verifyOtpText" : "I-verify ang email address gamit ang OTP para magpatuloy.",
        "sendOtpBtnText" : "Send OTP",
        "invalidErrorMessageText" : "Oops, ang email address na ito ay invalid. Paki-lagay ang wastong email address.",
        "invalidEmailCharacterRule" : "Inglis na lowercase or uppercase (a-z) na letra",
        "invalidEmailNumberRule" : "Numbers (0-9).",
        "invalidEmailSpecialCharacterRule" : "Mga karakter tulad ng period (.), apostrophe ('), dash (-), hash (#), percent (%), ampersand (&), slash (/), underscore (_) ay pwede rin",
        "invalidEmailSpecialSymbolRule" : "Ang mga karakter na simbolo ay hindi pwedeng manguna o mapwesto sa huli at hindi din pwedeng ipag-tabi",
        "invalidEmailDomainRule" : "@ ay pwede sa kalagitnaan lamang ng prefix & domain",
        "invalidEmailSectionHeaderDescriptionText" : "RFC-compliant characters lang ang pwede sa email address, tulad ng:",
        "emailAddressPrerequisitesText" : "Prerequisites ng Email Address",
        "confirmMessageEmail" : "Sigurado ka bang gusto mo itong i-cancel?",
        "invalidMobileNumberText" : "Oops, ang iyong mobile number ay invalid. Paki-provide ang tamang number.",
        "enterMobileNumber" : "Ilagay ang Mobile Number",
        "continue" : "Tumuloy",
        "mobileNotRegistered" : "Ang mobile number ay hindi registered sa theAsianparent.",
        "verifyMobileNumber" : "Paki-verify itong number sa OTP para tumuloy pa.",
        "emailAddress" : "Email Address",
        "mobileNumber" : "Mobile Number",
        "somethingWentWrong" : "Something went wrong",
        "infoText" : "info",
        "notification" : "Notifications",
    },
    ms: {
        "tapHeading" : "Selamat datang ke theAsianparent",
        "emailInputPlaceholder" : "Masukkan emel",
        "pwdInputPlaceholder" : "Masukkan kata laluan",
        "forgotPwdText" : "Terlupa Kata laluan?",
        "signInText" : "Daftar Masuk",
        "emailNotRegisterText" : "Emel tidak berdaftar dengan theAsianparent.",
        "verifyOtpText" : "Sila sahkan emel ini dengan OTP untuk teruskan.",
        "sendOtpBtnText" : "Hantar OTP",
        "invalidErrorMessageText" : "Oops, emel ini tidak sah. Sila masukkan emel yang sah.",
        "invalidEmailCharacterRule" : "Huruf kecil atau Huruf besar (a-z)",
        "invalidEmailNumberRule" : "Numbers (0-9).",
        "invalidEmailSpecialCharacterRule" : "Simbol seperti noktah (.), apostrof ('), sengkang (-), hash (#), peratus (%), ampersand (&), slash (/), underscore (_) dibenarkan",
        "invalidEmailSpecialSymbolRule" : "Simbol tidak boleh menjadi aksara pertama atau terakhir dan juga bersebelahan antara satu sama lain",
        "invalidEmailDomainRule" : "@ boleh digunakan hanya satu di antara awalan & domain",
        "invalidEmailSectionHeaderDescriptionText" : "Alamat emel mestilah mesti mengandungi hanya aksara yang mematuhi RFC, yang termasuk:",
        "emailAddressPrerequisitesText" : "Prasyarat Alamat Emel",
        "confirmMessageEmail" : "Anda pasti untuk batalkan?",
        "invalidMobileNumberText" : "Oops, nombor telefon tidak sah. Sila masukkan nombor yang betul.",
        "enterMobileNumber" : "Masukkan Nombor Telefon",
        "continue" : "Teruskan",
        "mobileNotRegistered" : "Nombor telefon tidak didaftarkan dengan theAsianparent.",
        "verifyMobileNumber" : "Sila sahkan nombor ini dengan OTP untuk teruskan.",
        "emailAddress" : "Emel",
        "mobileNumber" : "No.Telefon",
        "somethingWentWrong" : "Ada sesuatu yang tidak kena!",
        "infoText" : "info",
        "notification" : "Notifications",
    },
    vi: {
        "tapHeading" : "Chào mừng đến với Bé Yêu",
        "emailInputPlaceholder" : "Nhập địa chỉ email",
        "pwdInputPlaceholder" : "Nhập mật khẩu",
        "forgotPwdText" : "Quên mật khẩu?",
        "signInText" : "Đăng nhập",
        "emailNotRegisterText" : "Địa chỉ email chưa đăng ký với Bé Yêu.",
        "verifyOtpText" : "Vui lòng xác thực email này bằng mã OTP để tiếp tục.",
        "sendOtpBtnText" : "Gửi OTP.",
        "invalidErrorMessageText" : "Rất tiếc, địa chỉ email này không hợp lệ. Vui lòng cung cấp địa chỉ chính xác.",
        "invalidEmailCharacterRule" : "Chữ thường hoặc chữ hoa (a-z) chữ cái tiếng Anh",
        "invalidEmailNumberRule" : "Numbers (0-9).",
        "invalidEmailSpecialCharacterRule" : "Cho phép các ký tự như dấu chấm (.), dấu nháy đơn ('), dấu gạch ngang (-), dấu thăng (#), phần trăm (%), dấu và (&), dấu gạch chéo (/), dấu gạch dưới (_)",
        "invalidEmailSpecialSymbolRule" : "Các ký tự biểu tượng không được là ký tự đầu tiên hoặc ký tự cuối cùng và nó sẽ không xuất hiện lần lượt sau ký tự kia.",
        "invalidEmailDomainRule" : "@ chỉ có thể được sử dụng một lần ở giữa tiền tố & tên miền",
        "invalidEmailSectionHeaderDescriptionText" : "Địa chỉ email chỉ được bao gồm các ký tự tuân thủ RFC, bao gồm:",
        "emailAddressPrerequisitesText" : "Điều kiện cần về địa chỉ email",
        "confirmMessageEmail" : "Bạn có chắc là muốn hủy không?",
        "invalidMobileNumberText" : "Rất tiếc, đây là số điện thoại di động không hợp lệ. Vui lòng cung cấp số điện thoại đúng.",
        "enterMobileNumber" : "Nhập số điện thoại di động.",
        "continue" : "Tiếp tục",
        "mobileNotRegistered" : "Số điện thoại di động chưa được đăng ký với Bé Yêu.",
        "verifyMobileNumber" : "Vui lòng xác minh số này bằng OTP để tiếp tục.",
        "emailAddress" : "Địa chỉ email",
        "mobileNumber" : "Số điện thoại",
        "somethingWentWrong" : "Đã xảy ra sự cố",
        "infoText" : "info",
        "notification" : "Thông báo",
    }
}


    